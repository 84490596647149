import { USState } from './USState';
import { USTerritory } from './USTerritory';

export const USProvince = { ...USState, ...USTerritory };

/**
 * Describes any valid US state or territory as a
 * [USPS Postal Code](https://pe.usps.com/text/pub28/28apb.htm).
 */
export type USProvince = USState | USTerritory;
