'use client';

import { IProductLineItem } from '@/services/models/Cart/LineItem';
import { IProduct } from '@/services/models/Product';
import { VariationAttributeType } from '@/services/models/Product/variation-attributes';

import { msg, msgf } from '@/services/isomorphic/I18NService';
import { IOrderLine } from '@/services/models/Order';
import { observer } from 'mobx-react-lite';
import { Button } from '../../core-ui/Button';
import SkeletonLoader from '../../utility/SkeletonLoader';

import { useProduct } from '../../../hooks/useProduct';

import { toGroupString } from '../../../utils/product-utils';
import S from './styles.module.scss';
import { cart_cartItem_color } from "@/lang/__generated__/ahnu/cart_cartItem_color";
import { cart_cartItem_editDetails } from "@/lang/__generated__/ahnu/cart_cartItem_editDetails";
import { cart_cartItem_genderAndSize } from "@/lang/__generated__/ahnu/cart_cartItem_genderAndSize";
import { cart_cartItem_sizeDisplay } from "@/lang/__generated__/ahnu/cart_cartItem_sizeDisplay";

/**
 * Line Item details props definition.
 */
export interface ILineItemDetailsProps {
  /** The product line item from cart. */
  item: IProductLineItem | IOrderLine;

  /**
   * The style of the product line item.
   * Simple option is displayed without cart components, used in
   * the AddToCartModal.
   */
  variant?: 'default' | 'preview' | 'simple';

  /**
   * Callback that will be executed when the `Edit Details` link is
   * clicked on this line item.
   *
   * @param product - The product being edited.
   */
  onEdit?: (product: IProduct) => void;

  /**
   * If the showActions is false it should hide the action buttons
   * in the Product Line Item.
   */
  showActions?: boolean;
}

/** The details section of the line item component. */
export const LineItemDetails = observer(function LineItemDetails({
  item,
  variant,
  onEdit,
  showActions = true
}: ILineItemDetailsProps) {
  const { sku } = item;

  const product = useProduct(sku);

  const color = product?.getSelectedAttributeByType(
    VariationAttributeType.Color
  );

  const sizeAttribute = product?.getSelectedAttributeByType(
    VariationAttributeType.Size
  );

  const group = product?.groupSize.group;
  const size = product?.groupSize.size;

  const genderValue = group ? toGroupString(group, sku) : null;
  const genderAndSizeDisplayText = genderValue
    ? msgf(cart_cartItem_genderAndSize, {
        size: size ?? '?',
        gender: genderValue ?? ''
      })
    : size;

  return (
    <div className={S.details}>
      {sizeAttribute?.name ? (
        <span>
          {variant !== 'simple' ? genderAndSizeDisplayText : genderValue}
        </span>
      ) : (
        <SkeletonLoader className={S.sizeLoader} />
      )}

      {color?.name ? (
        `${msg(cart_cartItem_color)}
      : ${color.name}`
      ) : (
        <SkeletonLoader className={S.colorLoader} />
      )}

      {variant === 'simple' && (
        <span>{msgf(cart_cartItem_sizeDisplay, { size: size ?? '' })}</span>
      )}

      {variant !== 'simple' && product && (
        <span className={S.inStock}>{product.availabilityStateText}</span>
      )}

      {showActions && (
        <Button
          variant="text"
          onClick={() => {
            if (onEdit && product) onEdit(product);
          }}
        >
          {msg(cart_cartItem_editDetails)}
        </Button>
      )}
    </div>
  );
});
