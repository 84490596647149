'use client';

import { ProductLineItemModel } from '@/services/models/Cart/LineItem';
import { observer } from 'mobx-react-lite';
import S from './styles.module.scss';

/**
 * Props for the `LineItemPromotions` component.
 */
export interface ILineItemPromotionsProps {
  /** The line item whose promotions will be displayed. */
  lineItem: ProductLineItemModel;
}

/**
 * Displays the promotions applied to a line item.
 */
export const LineItemPromotions = observer(function LineItemPromotions({
  lineItem
}: ILineItemPromotionsProps) {
  const { promotions } = lineItem;

  if (promotions.length === 0) {
    return null;
  }

  const promosToRender: Record<string, number> = {};

  for (const p of promotions) {
    const { visible } = p;

    if (!visible) {
      continue;
    }

    // TODO: Generate generic labels per promotion type.
    const label = p.label ?? 'Promotion';

    if (!promosToRender[label]) {
      promosToRender[label] = 0;
    }

    promosToRender[label] += 1;
  }

  return Object.entries(promosToRender).map(([key, value]) => {
    return (
      <div
        key={key}
        className={S.promotion}
      >{`Promotion applied: ${key} (${value})`}</div>
    );
  });
});
