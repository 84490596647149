import { FunctionComponent, PropsWithChildren, useMemo } from 'react';

import { PageService } from '@/services/isomorphic/PageService';
import { IPage } from '@/services/models/Page';
import { PageContext } from './PageContext';

export interface IPageProviderProps extends PropsWithChildren {
  /** The page that is supplied to initialize the provider. */
  page: IPage;
}

/**
 * Checkout Navigation Provider.
 */
export const PageProvider: FunctionComponent<IPageProviderProps> = ({
  page,
  children
}) => {
  PageService.page = page;

  const contextValue = useMemo<IPageProviderProps>(() => {
    PageService.page = page;
    return {
      page
    };
  }, [page]);

  return (
    <PageContext.Provider value={contextValue}>{children}</PageContext.Provider>
  );
};
