'use client';

import { CSSProperties, FunctionComponent, SyntheticEvent } from 'react';
import { IBoxProps, allowedElements } from './IBoxProps';

/**
 * Customized next/link with analytics and composbale styling.
 * @throws - If dynamic tag is not on the allowed list.
 */
export const Box: FunctionComponent<IBoxProps> = ({
  as: Tag = 'div',
  className = '',
  type,
  disabled,
  title,
  ariaLabel,
  key,
  onClick,
  onKeyPress,
  children,
  ...styles
}) => {
  if (!allowedElements.includes(Tag)) {
    throw new Error(Tag + ' is not allowed as a dynamic tag');
  }
  const handleClick = onClick ? (e: SyntheticEvent): void => onClick(e) : null;
  const handleKeyPress = onKeyPress
    ? (e: SyntheticEvent): void => onKeyPress(e)
    : null;
  return (
    <Tag
      onClick={handleClick ?? undefined}
      onKeyPress={handleKeyPress ?? handleClick ?? undefined}
      style={styles as CSSProperties}
      className={className}
      type={type}
      title={title}
      aria-label={ariaLabel}
      disabled={disabled}
      key={key}
    >
      {children}
    </Tag>
  );
};
