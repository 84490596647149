import { useApplePay } from '@/react/hooks/useApplePay';
import I18NService from '@/services/isomorphic/I18NService';
import { type FC } from 'react';
import type IStylable from '../traits/IStylable';
import {
  IInternalApplePayButtonProps,
  InternalApplePayButton
} from './internal/InternalApplePayButton';

/**
 * The props for the `ApplePayButton` component.
 */
export interface IApplePayButtonProps
  extends IStylable,
    Omit<IInternalApplePayButtonProps, 'buttonStyle' | 'type' | 'locale'> {
  /**
   * The theme of the Apple Pay button.
   * @default 'black'
   */
  theme?: IInternalApplePayButtonProps['buttonStyle'];
  /**
   * The variant of the Apple Pay button.
   * @default 'buy'
   */
  variant?: IInternalApplePayButtonProps['type'];
  /**
   * The locale of the Apple Pay button.
   * @default I18NService.currentLocale.toString()
   */
  locale?: IInternalApplePayButtonProps['locale'];
}

/**
 * A thin wrapper over the `InternalApplePayButton` component that handles
 * the loading of the Apple Pay JS SDK, supports Storybook, and exposes
 * a slightly nicer API.
 */
export const ApplePayButton: FC<IApplePayButtonProps> = ({
  theme = 'black',
  variant = 'buy',
  locale = I18NService.currentLocale.toString() as ApplePayButtonLocale,
  ...props
}) => {
  const [script, isEnabled] = useApplePay();

  return (
    <>
      {script}
      {isEnabled && (
        <InternalApplePayButton
          type={variant}
          buttonStyle={theme}
          locale={locale}
          {...props}
        />
      )}
    </>
  );
};
