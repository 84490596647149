import { CardNetwork } from '@/constructs/CardNetwork';
import { maskString } from '@/utils/string-utils';
import ConfigurationService from '../isomorphic/ConfigurationService';
import { msg } from '../isomorphic/I18NService';
import { ConfigModel } from '../models/Config';
import { checkout_paymentTypes_amex } from "@/lang/__generated__/ahnu/checkout_paymentTypes_amex";
import { checkout_paymentTypes_dinersint } from "@/lang/__generated__/ahnu/checkout_paymentTypes_dinersint";
import { checkout_paymentTypes_diners } from "@/lang/__generated__/ahnu/checkout_paymentTypes_diners";
import { checkout_paymentTypes_mastercard } from "@/lang/__generated__/ahnu/checkout_paymentTypes_mastercard";
import { checkout_paymentTypes_discover } from "@/lang/__generated__/ahnu/checkout_paymentTypes_discover";
import { checkout_paymentTypes_jcb } from "@/lang/__generated__/ahnu/checkout_paymentTypes_jcb";
import { checkout_paymentTypes_union } from "@/lang/__generated__/ahnu/checkout_paymentTypes_union";
import { checkout_paymentTypes_visa } from "@/lang/__generated__/ahnu/checkout_paymentTypes_visa";
import { checkout_paymentTypes_unknown } from "@/lang/__generated__/ahnu/checkout_paymentTypes_unknown";

/**
 * Mask a provided credit card number. The format of the mask will be determined
 * by the card's network.
 *
 * @see The {@link maskString} util.
 *
 * @param creditCardNumber - The credit card number to mask.
 * @returns The masked credit card number .
 *
 * @example Visa card: `4111 1111 1111 1111` -> `**** **** **** 1111`
 * @example Amex card: `3782 822463 10005` -> `**** ****** *0005`
 */
export const maskCreditCardNumber = (creditCardNumber: string): string => {
  // Sanitize the card number by removing all non-digit chars.
  const sanitizedCardNumber = creditCardNumber.replaceAll(/\D/g, '');

  const config = ConfigurationService.getConfig('cards');
  const spacingPatterns = config.getSetting('spacingPatterns').value;

  // Get the card's network and the configured spacing patterns for it
  const network = getCardNetworkFromCardNumber(sanitizedCardNumber);
  const spacingPatternsForNetwork =
    spacingPatterns[network as keyof typeof spacingPatterns]?.value;

  // If there are no patterns for the provided network
  if (!spacingPatternsForNetwork) {
    // Mask the sanitized card number without adding any spacing pattern and
    // call it a day.
    return maskString(sanitizedCardNumber, {
      trailingChars: 4
    });
  }

  // If there are patterns for the network though, check if there is an entry
  // for the length of the supplied card number.
  const validLengths = Object.keys(spacingPatternsForNetwork).map((k) =>
    parseInt(k, 10)
  );

  const matchedLength = validLengths.find(
    (length) => length === sanitizedCardNumber.length
  );

  // If the length of the supplied card number is not on the list, mask it
  // without a spacing pattern.
  if (!matchedLength) {
    return maskString(sanitizedCardNumber, {
      trailingChars: 4
    });
  }

  // If there is a spacing pattern for the card's length...
  const spacingPattern = spacingPatternsForNetwork[
    matchedLength.toString() as keyof typeof spacingPatternsForNetwork
  ] as ConfigModel<string>;

  // Identify the size of each digit group.
  const digitGroups = spacingPattern.value.split(',');

  let currentStartPoint = 0;
  const chunks = [];

  for (let i = 0; i < digitGroups.length - 1; i += 1) {
    const groupSize = parseInt(digitGroups[i], 10);

    // Mask and push all the groups ("chunks") of the card's digits except for
    // the last one
    chunks.push(
      maskString(
        sanitizedCardNumber.substring(
          currentStartPoint,
          currentStartPoint + groupSize
        )
      )
    );

    currentStartPoint += groupSize;
  }

  // The last group will only go as far as the card's length...
  const lastGroup = sanitizedCardNumber.substring(
    currentStartPoint,
    sanitizedCardNumber.length
  );

  // ...and will be left with a number of digits exposed at the end (a max of
  // four).
  chunks.push(
    maskString(lastGroup, { trailingChars: Math.min(4, lastGroup.length) })
  );

  return chunks.join(' ');
};

/**
 * Given a card number, either partial or complete, identify its network..
 *
 * @param cardNumber - User's card number.
 *
 * @returns The card network, as a member of the {@link CardNetwork} enum, or
 * {@link CardNetwork.Unknown} if the network can't be identified.
 */
export const getCardNetworkFromCardNumber = (
  cardNumber: string
): CardNetwork => {
  const config = ConfigurationService.getConfig('cards');
  const allowedCards = config.getSetting('allowedCards').value;
  const iinRanges = config.getSetting('iinRanges').value;

  const sanitizedCardNumber = cardNumber.replaceAll(/\D/g, '');

  for (const network of Object.keys(iinRanges)) {
    if (allowedCards[network as keyof typeof allowedCards]?.value) {
      const ranges = iinRanges[network as keyof typeof iinRanges].value;

      for (const range of ranges.split(',')) {
        const [rangeStart, rangeEnd] = range.split('-');
        const digitsToGrab = (rangeEnd ?? rangeStart).length;
        const firstDigits = sanitizedCardNumber.substring(0, digitsToGrab);

        if (firstDigits === rangeStart) {
          return network as CardNetwork;
        }

        if (rangeEnd) {
          const numRangeStart = parseInt(rangeStart, 10);
          const numRangeEnd = parseInt(rangeEnd, 10);
          const numFirstDigits = parseInt(firstDigits, 10);

          if (
            numRangeStart <= numFirstDigits &&
            numFirstDigits <= numRangeEnd
          ) {
            return network as CardNetwork;
          }
        }
      }
    }
  }

  return CardNetwork.Unknown;
};

/**
 * Gets the card type resource string so that you can render the name of the
 * CardNetwork.
 *
 * @param type - The card type.
 * @returns A string used to access the resource string.
 */
export const getCardBrandString = (type: CardNetwork): string => {
  switch (type) {
    case CardNetwork.AmericanExpress:
      return msg(checkout_paymentTypes_amex);
    case CardNetwork.DinersClubInternational:
      return msg(checkout_paymentTypes_dinersint);
    case CardNetwork.DinersClubUSandCanada:
      return msg(checkout_paymentTypes_diners);
    case CardNetwork.MasterCard:
      return msg(checkout_paymentTypes_mastercard);
    case CardNetwork.DiscoverCard:
      return msg(checkout_paymentTypes_discover);
    case CardNetwork.JCB:
      return msg(checkout_paymentTypes_jcb);
    case CardNetwork.UnionPay:
      return msg(checkout_paymentTypes_union);
    case CardNetwork.Visa:
      return msg(checkout_paymentTypes_visa);
    default:
      return msg(checkout_paymentTypes_unknown);
  }
};
