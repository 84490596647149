import { USProvince } from './US/USProvince';

export const Province = { ...USProvince };

/**
 * Describes a valid province: an administrative division within a country.
 *
 * @see [Province on Wikipedia](https://en.wikipedia.org/wiki/Province)
 */
export type Province = USProvince; // When adding new locales, extend this type.
