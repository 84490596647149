'use client';

import { classes } from '@/next-utils/css-utils/scss-utils';
import type CartVM from '@/react/view-models/CartVM';
import { AbortPaymentError } from '@/services/isomorphic/PaymentRequestService';
import { useCallback, type FC } from 'react';
import type IStylable from '../../traits/IStylable';
import { ApplePayButton, type IApplePayButtonProps } from '../ApplePayButton';
import S from './styles.module.scss';

/**
 * The props for the `BuyWithApplePayButton` component.
 */
export interface IBuyWithApplePayButtonProps
  extends IStylable,
    Omit<IApplePayButtonProps, 'onClick' | 'locale'> {
  /**
   * The cart view model which contains the order to be placed with Apple Pay.
   */
  cart: CartVM;
}

/**
 * A higher-level Apple Pay button component that enables bringing up
 * the payment sheet and placing orders for the given cart.
 */
export const BuyWithApplePayButton: FC<IBuyWithApplePayButtonProps> = ({
  cart,
  className,
  ...props
}) => {
  const handlePayment = useCallback(async (): Promise<void> => {
    try {
      const order = await cart.orderWithApplePay();
      await cart.empty();
      console.log('Order placed:', order);
    } catch (error) {
      if (error instanceof AbortPaymentError) {
        console.log('Payment aborted:', error);
        return;
      }
      throw error;
    }
  }, [cart]);

  return (
    <ApplePayButton
      variant="buy"
      onClick={handlePayment}
      className={classes(S.buyWithApplePayBtn, className)}
      {...props}
    />
  );
};
