import { FunctionComponent, useEffect } from 'react';
import Head from 'next/head';
import { IPage, PageModel } from '@/services/models/Page';
import { Osano } from '@/react/components/cookie-preferences';
import { StructuredData } from '../StructuredData';

export interface IHeadProps {
  /**
   * The page used to fill in the title and description.
   */
  page: IPage;
}

/**
 * A component for holding meta data added to the head.
 */
export const PageHead: FunctionComponent<IHeadProps> = ({ page }) => {
  const pageModel = new PageModel(page);

  return (
    <Head>
      <meta name="viewport" content="width=device-width, initial-scale=1" />
      <title>{pageModel.title}</title>
      <meta name="description" content={pageModel.description} />
      <meta name="referrer" content="origin-when-cross-origin" />
      {pageModel.canonicalURL && (
        <link rel="canonical" href={pageModel.canonicalURL.href} />
      )}
      {pageModel?.pageMetaData?.robots && (
        <meta name="robots" content={pageModel.pageMetaData.robots.join(',')} />
      )}
    </Head>
  );
};
