import { IForm } from '@/react/view-models/Form';
import { Nullable } from '@/type-utils';
import { createContext } from 'react';

/**
 * The context object that contains a form model of some other type and
 * a submit callback.
 */
export interface IFormContext {
  /**
   * The form model associated with this form components. It gets passed
   * into the context and shouldn't ever be set again.
   */
  form: IForm;
  /** The form is currently busy processing. */
  isLoading: boolean;
  /**
   * Sets the isLoading parameter.
   * @param value - The value isLoading will be set to.
   */
  setIsLoading: (value: boolean) => void;
}

export const FormContext = createContext<Nullable<IFormContext>>(null);
