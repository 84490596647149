'use client';

import { classes } from '@/next-utils/css-utils/scss-utils';
import { ProductLineItemModel } from '@/services/models/Cart/LineItem';
import { MoneyModel } from '@/services/models/Money';
import { PriceModel } from '@/services/models/Price';
import { observer } from 'mobx-react-lite';
import S from './styles.module.scss';

/**
 * Props for the `LineItemTotal` component.
 */
export interface ILineItemTotalProps {
  /**
   * The line item whose total will be displayed.
   */
  lineItem: ProductLineItemModel;

  /**
   * Whether to display the total for each item or the total for all items.
   */
  type: 'total' | 'each';
}

/**
 * Displays the total for a line item.
 */
export const LineItemTotal = observer(function LineItemTotal({
  lineItem,
  type
}: ILineItemTotalProps) {
  const { subtotal, netTotal, unitPrice, netUnitPrice } = lineItem;

  const isSubtotalGreaterThanTotal = subtotal.isGreaterThan(netTotal);
  const price = PriceModel.from(unitPrice);

  const strikeThroughText =
    type === 'each' ? price.retailPrice.toString() : subtotal.toString();
  const defaultText =
    type === 'each' ? netUnitPrice.toString() : netTotal.toString();

  return isSubtotalGreaterThanTotal ? (
    <>
      <span className={classes(S.priceValue, S.priceStrikethrough)}>
        {strikeThroughText}
      </span>
      <span className={S.priceValue}>{defaultText}</span>
    </>
  ) : (
    <span className={S.priceValue}>{defaultText}</span>
  );
});
