import { EnvironmentService } from '@/services/isomorphic/EnvironmentService';
import { memo, useLayoutEffect, useRef, type FC } from 'react';

export interface IInternalApplePayButtonProps
  extends Omit<
    HTMLApplePayButtonElementAttributes,
    'class' | 'for' | 'buttonstyle'
  > {
  /**
   * Callback to be executed when the Apple Pay button is clicked.
   */
  onClick?: () => void;

  /**
   * Additional classes to apply to the Apple Pay button element.
   */
  className?: string;

  /**
   * The theme of the Apple Pay button.
   * @default 'black'
   * @see {@link https://developer.apple.com/documentation/apple_pay_on_the_web/applepaybutton/3820127-buttonstyle buttonstyle}
   */
  buttonStyle?: HTMLApplePayButtonElementAttributes['buttonstyle'];
}

/**
 * A React wrapper component for the Apple Pay button web component provided
 * by the Apple Pay JS SDK. It provides a simple API for handling click events
 * and customizing the button's appearance.
 *
 * It does **not** automatically load the Apple Pay JS SDK or integrate with
 * the `ApplePaySession` API out of the box.
 * @see {@link ../ApplePayButton.tsx}
 */
export const InternalApplePayButton: FC<IInternalApplePayButtonProps> = memo(
  function ApplePayButton({ onClick, className, buttonStyle, ...attrs }) {
    const ref = useRef<HTMLApplePayButtonElement>(null);

    useLayoutEffect(() => {
      if (!onClick) {
        return undefined;
      }

      const abortController = new AbortController();

      ref.current?.addEventListener('click', onClick, {
        signal: abortController.signal
      });

      return () => {
        abortController.abort();
      };
    }, [onClick]);

    // If we are in an isolated context, but the browser does not
    // support Apple Pay, render a message.
    if ((typeof __isolatedContext__ !== "undefined") && !window.ApplePaySession) {
      return 'This browser does not support Apple Pay.';
    }

    return (
      <apple-pay-button
        ref={ref}
        class={className}
        buttonstyle={buttonStyle}
        {...attrs}
      />
    );
  }
);
