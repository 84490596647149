'use client';

import { observer } from 'mobx-react-lite';
import { ChangeEvent, useCallback, useMemo, useState } from 'react';

import { ProductLineItemModel } from '@/services/models/Cart/LineItem';
import { IProduct } from '@/services/models/Product';

import ConfigurationService from '@/services/isomorphic/ConfigurationService';
import { msg } from '@/services/isomorphic/I18NService';
import { EventType } from '@/services/isomorphic/UserInteractionService';

import { classes } from '@/next-utils/css-utils/scss-utils';
import { useErrorAlert } from '@/react/hooks/useErrorAlert';
import CartVM from '@/react/view-models/CartVM';
import {
  CartQuantityExceededError,
  LineItemQuantityExceededError
} from '@/services/models/Cart';
import { Button } from '../../core-ui/Button';
import { Icon, IconSizeProp, IconTypes } from '../../core-ui/Icon';
import { Image } from '../../core-ui/Image';
import { Select } from '../../core-ui/Select';
import { LineItemDetails } from '../LineItemDetails';

import { Spinner } from '../../core-ui/Spinner';
import { LineItemPromotions } from './LineItemPromotions';
import { LineItemTotal } from './LineItemTotal';
import S from './styles.module.scss';
import { cart_errors_lineQuantityExceeded } from "@/lang/__generated__/ahnu/cart_errors_lineQuantityExceeded";
import { cart_errors_cartTotalQuantityExceeded } from "@/lang/__generated__/ahnu/cart_errors_cartTotalQuantityExceeded";
import { cart_cartItem_each } from "@/lang/__generated__/ahnu/cart_cartItem_each";
import { cart_cartItem_quantity } from "@/lang/__generated__/ahnu/cart_cartItem_quantity";
import { general_total } from "@/lang/__generated__/ahnu/general_total";
import { cart_cartItem_remove } from "@/lang/__generated__/ahnu/cart_cartItem_remove";
import { cart_cartItem_addToWishList } from "@/lang/__generated__/ahnu/cart_cartItem_addToWishList";

/**
 * Product Line Item props definition.
 */
export interface IProductLineItemProps {
  /** The product line item from cart. */
  item: ProductLineItemModel;

  /**
   * Controls the layout of the component displaying the content in column
   * or row style.
   */
  direction?: 'column' | 'row';

  /**
   * The style of the product line item.
   * Simple option is displayed without cart components, used in
   * the AddToCartModal.
   */
  variant?: 'default' | 'preview' | 'simple';

  /** The cart view model associated with the line item. */
  cart: CartVM;

  /**
   * Callback that will be executed when the `Edit Details` link is
   * clicked on this line item.
   *
   * @param product - The product being edited.
   */
  onEdit?: (product: IProduct) => void;

  /**
   * If the showActions is false it should hide the action buttons
   * in the Product Line Item.
   */
  showActions?: boolean;
}

/**
 * Displays the product line item in the cart.
 */
export const ProductLineItem = observer(function ProductLineItem({
  item,
  direction = 'row',
  variant = 'default',
  cart,
  onEdit,
  showActions = true
}: IProductLineItemProps) {
  const { name, image, quantity, uuid } = item;

  const [isLoading, setIsLoading] = useState(false);

  const isWishlistEnabled =
    ConfigurationService.getConfig('product').getSetting(
      'wishlist.enable'
    ).value;

  const { alert } = useErrorAlert();

  /**
   * Updates the quantity of a line item in a cart.
   * @param cart - The cart UUID the item belongs to.
   * @param productId - The product item UUID to be updated.
   * @param quantity - The new quantity.
   * @returns - Promise that resolves on successful operation.
   */
  const handleQuantityChange = useCallback(
    async (cart: CartVM, productID: string, quantity: number) => {
      setIsLoading(true);
      try {
        await cart.updateLineItem(productID, quantity);
      } catch (err) {
        if (err instanceof LineItemQuantityExceededError) {
          alert(msg(cart_errors_lineQuantityExceeded));
        } else if (err instanceof CartQuantityExceededError) {
          alert(msg(cart_errors_cartTotalQuantityExceeded));
        } else {
          throw err;
        }
      } finally {
        setIsLoading(false);
      }
    },
    [alert]
  );

  /**
   * Remove the product from the cart.
   * @param cart - The cart UUID the item belongs to.
   * @param productID - The product item UUID to be updated.
   * @returns - Promise that resolves on successful operation.
   */
  const handleRemoveFromCart = useCallback(
    async (cart: CartVM, itemID: string) => {
      setIsLoading(true);
      try {
        await cart.removeItem(itemID);
      } finally {
        setIsLoading(false);
      }
    },
    []
  );

  const quantityOptions = useMemo(
    () =>
      Array.from({ length: cart.maxQuantityPerLine }, (_, i) => ({
        value: i + 1
      })),
    [cart.maxQuantityPerLine]
  );

  const onProductQuantityChange = useCallback(
    (e: ChangeEvent<HTMLSelectElement>) => {
      handleQuantityChange(cart, uuid, Number(e.target.value));
    },
    [cart, handleQuantityChange, uuid]
  );

  return (
    <article
      className={classes(S.container, {
        [S.productRow]: direction === 'row',
        [S.productColumn]: direction !== 'row',
        [S.simple]: variant === 'simple'
      })}
    >
      <div className={S.name}>{name}</div>

      <div className={S.product}>
        <div className={S.productDetails}>
          {image && (
            <div className={S.productImg}>
              <Image image={image} fill />
            </div>
          )}
          <LineItemDetails
            item={item}
            variant={variant}
            onEdit={onEdit}
            showActions={showActions}
          />
        </div>
        {variant !== 'simple' && (
          <div className={S.productOptions}>
            <div className={S.appliedPromotions}>
              <LineItemPromotions lineItem={item} />
            </div>
            <div className={S.options}>
              <div className={S.price}>
                <span>{msg(cart_cartItem_each)}</span>
                <LineItemTotal lineItem={item} type="each" />
              </div>
              <div className={S.price}>
                {variant === 'default' ? (
                  <Select
                    id="product-quantity"
                    label={msg(cart_cartItem_quantity)}
                    options={quantityOptions}
                    value={quantity}
                    onChange={onProductQuantityChange}
                    isOptional
                  />
                ) : (
                  <>
                    <span>{msg(cart_cartItem_quantity)}</span>
                    <span className={S.priceValue}>{quantity}</span>
                  </>
                )}
              </div>

              <div className={S.price}>
                <span className={S.alignRight}>{msg(general_total)}</span>
                <LineItemTotal lineItem={item} type="total" />
              </div>
            </div>

            {showActions && (
              <div className={S.actions}>
                <Button
                  className={S.remove}
                  variant="text"
                  onClick={() => {
                    handleRemoveFromCart(cart, uuid);
                  }}
                  interactionDetails={{
                    action: EventType.ProductRemove,
                    lineItem: item
                  }}
                >
                  <Icon icon={IconTypes.Trash} size={IconSizeProp.SizeLG} />
                  {msg(cart_cartItem_remove)}
                </Button>
                {isWishlistEnabled && (
                  <Button variant="text">
                    <Icon icon={IconTypes.Heart} />
                    {msg(cart_cartItem_addToWishList)}
                  </Button>
                )}
              </div>
            )}
            {isLoading && <Spinner size="1em" className={S.spinner} />}
          </div>
        )}
      </div>
    </article>
  );
});

/** Props for the ProductLineItemList component. */
export type IProductLineItemListProps = Omit<IProductLineItemProps, 'item'>;

/**
 * Displays a list of product line items in the cart.
 */
export const ProductLineItemList = observer(function ProductLineItemList({
  cart,
  ...rest
}: IProductLineItemListProps) {
  return cart.products.map((item) => (
    <ProductLineItem key={item.uuid} item={item} cart={cart} {...rest} />
  ));
});
