'use client';

// This file needs to be a client component since it passes functions to
// other client components.

import { FunctionComponent } from 'react';

import { msg } from '@/services/isomorphic/I18NService';
import { NavigationContentModel } from '@/services/models/Content';
import { useBrandLocaleValue } from '@/react/hooks/useBrandLocaleValue';
import Socials from '../../Socials';
import { MarketingBanner } from '../../cms-content';
import { getComponents } from '../../cms-content/utils/getComponent';
import { SkipToLinkType } from '../SkipToLink/SkipToLinkType';
import { CategoryLinks } from './CategoryLinks';
import { FooterBottom } from './FooterBottom';
import { IFooterProps } from './IFooterProps';
import PromotionalEmailSignUpForm from './PromotionalEmailSignUpForm';

import S from './styles.base.module.scss';
import { Link } from '../../core-ui/Link';
import { footer_bottomLink } from "@/lang/__generated__/ahnu/footer_bottomLink";

/**
 * Footer contains social links, and additional navigiational options.
 */
export const Footer: FunctionComponent<IFooterProps> = ({
  navigationContent,
  hideFooterNavigation = false
}) => {
  const navigationContentModel = NavigationContentModel.from(navigationContent);
  const bannerItem = navigationContentModel.footerBanner?.items[0];

  /**
   * Whether or not to show the bottom promo link.
   */
  const showBottomPromoLink = useBrandLocaleValue<boolean>(
    () => ({
      default: false
    }),
    []
  );

  return (
    <>
      {bannerItem && (
        <MarketingBanner
          contentResolver={getComponents}
          item={bannerItem}
          closeButton={false}
          variant="footer"
        />
      )}
      <footer id={SkipToLinkType.Footer} className={S.root} role="contentinfo">
        <div className={S.container}>
          {!hideFooterNavigation && (
            <div className={S.main}>
              <div className={S.row}>
                <div className={S.company}>
                  <PromotionalEmailSignUpForm />
                  <div className={S.socials}>
                    <Socials />
                  </div>
                </div>
                <ul className={S.links}>
                  <CategoryLinks />
                </ul>
              </div>

              {showBottomPromoLink && (
                <div className={S.bottomLink}>
                  <Link
                    className={S.link}
                    href="/guarantee"
                    variant="text-underline"
                  >
                    {msg(footer_bottomLink)}
                  </Link>
                </div>
              )}
            </div>
          )}
          <FooterBottom />
        </div>
      </footer>
    </>
  );
};
