/**
 * @file A collection of utility functions for working with the DOM nodes and elements and
 * performing calculations on the same.
 */
import { EnvironmentService } from '@/services/isomorphic/EnvironmentService';
import { Nullable } from '@/type-utils';
import { ClientOnlyCodeInServerError } from '@/utils/errors';

/**
 * For performance reasons, we want to measure the scrollbar width once and then cache
 * the result. This avoids needing to manipulate the DOM every time we need to measure
 * the scrollbar width. This is relatively safe to do because the scrollbar width is
 * unlikely to change during the lifetime of the application prior to a page refresh.
 */
let knownScrollbarWidth: Nullable<number> = null;

/**
 * A utility function to measure the width of a scrollbar on the current system. On
 * systems where the scrolls are visible, this function will return a positive number
 * representing the width of the scrollbar. On systems where the scrolls are not visible,
 * this function will return 0.
 * @returns The width of the scrollbar on the current system.
 */
export const measureScrollbarWidth = (): number => {
  if ((typeof window === "undefined")) {
    throw new ClientOnlyCodeInServerError(
      '`measureScrollbarWidth` cannot be called on the server.'
    );
  }

  // If we have already measured the scrollbar width, return the cached value.
  if (knownScrollbarWidth !== null) {
    return knownScrollbarWidth!;
  }

  // We create a div here to perform the measurement of the scrollbar width width against.
  const scrollDiv = document.createElement('div');
  scrollDiv.style.cssText =
    'width: 99px; height: 99px; overflow: scroll; position: absolute; top: -9999px;';

  // Append this div to the body.
  document.body.appendChild(scrollDiv);

  // Calculate the scrollbar width based on the difference between the offsetWidth and the
  // clientWidth. If there are scrollbars on this system, the difference will be greater
  // than 0. Otherwise, the difference will be 0.
  const scrollbarWidth = scrollDiv.offsetWidth - scrollDiv.clientWidth;

  // Synchronously remove the div from the body so that it doesn't affect the layout of the
  // page.
  document.body.removeChild(scrollDiv);

  // Cache the scrollbar width so that we don't need to measure it again.
  knownScrollbarWidth = scrollbarWidth;
  return scrollbarWidth;
};
