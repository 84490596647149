import { DTO, Nullable } from '@/type-utils';
import { IMediaOptimizationOptions } from '../../isomorphic/MediaService';
import Model from '../Model';

import { IMedia } from '.';

/**
 * @inheritDoc
 */
export default abstract class MediaModel<T extends DTO<IMedia> = DTO<IMedia>>
  extends Model<T>
  implements IMedia
{
  /** @inheritDoc */
  public readonly uuid: string;

  private _src: string;
  private _width: Nullable<number>;
  private _height: Nullable<number>;

  protected _optimized: boolean;

  /**
   * Builds a model from any Media representation.
   * @param media - A Media representation.
   */
  public constructor(media: T) {
    super(media);

    this.uuid = media.uuid;
    this._src = media.src;

    if (media.height) this._height = media.height;
    if (media.width) this._width = media.width;

    // Optimized will always start as `false`, and will only be set to `true`
    // after the `optimize` method is called.
    this._optimized = false;
  }

  /** @inheritdoc */
  public update(media: T): void {
    this._src = media.src;

    if (media.height) this._height = media.height;
    if (media.width) this._width = media.width;
  }

  /** @inheritdoc */
  public get src(): string {
    return this._src;
  }

  /** @inheritdoc */
  public get width(): Nullable<number> {
    return this._width;
  }

  /** @inheritdoc */
  public get height(): Nullable<number> {
    return this._height;
  }

  /** @inheritdoc */
  public get optimized(): boolean {
    return this._optimized;
  }

  /**
   * Optimize this media object. This model will be updated
   * with a new, optimized version of the media it represents.
   *
   * @param options - Optimization options.
   */
  public abstract optimize(options: IMediaOptimizationOptions): void;

  /**
   * Creates a DTO representation of this Model.
   * @returns A DTO representation of this Media Model.
   */
  public toDTO(): T {
    return {
      uuid: this.uuid,
      height: this.height,
      width: this.width,
      src: this.src
    } as T;
  }
}
