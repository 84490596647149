'use client';

import { FunctionComponent, ReactNode } from 'react';

import ConfigurationService from '@/services/isomorphic/ConfigurationService';
import { useBrandLocaleValue } from '@/react/hooks/useBrandLocaleValue';
import { Accordion } from '../../../core-ui/Accordion';
import { Link } from '../../../core-ui/Link';
import { IconTypes, IconAnimations } from '../../../core-ui/Icon';
import { Breakpoint, Breakpoints, Default } from '../../../core-ui/Breakpoints';

import S from './styles.base.module.scss';

/**
 * List of links pulled from navigation elements.
 */
export const CategoryLinks: FunctionComponent = () => {
  const desktop: Array<ReactNode> = [];
  const mobile: Array<ReactNode> = [];

  const config = ConfigurationService.getConfig('footer');
  const footerMenu = config.getSetting('footerMenu').value;
  // Retrieve ecommerce related values
  const ecommerceEnabled =
    ConfigurationService.getConfig('ecommerce').getSetting(
      'ecommerceEnabled'
    ).value;
  const keys = ConfigurationService.getConfig('ecommerce')
    .getSetting('ecommerceKeys')
    .value.map((key) => key as unknown as string);

  // Icon types depend on brand
  const AccordionIconType = useBrandLocaleValue<IconTypes>(
    () => ({
      default: IconTypes.ChevronDown,
      AHNU: IconTypes.ChevronDownLight
    }),
    []
  );

  footerMenu.forEach((header) => {
    // If the header is the key for an ecommerce enabled component, and ecommerce is disabled, skip it.
    const headerId: string = header.id.value;
    if (keys.some((key) => headerId === key) && !ecommerceEnabled) {
      return;
    }
    const list = (
      <ul className={S.links}>
        {header.links.value?.map(({ id, label, href, newTab, needsLogin }) =>
          // If the link ID is the key for an ecommerce enabled component, and ecommerce is disabled, skip it.
          keys.some((key) => id.value === key) && !ecommerceEnabled ? null : (
            <li className={S.item} key={id.value}>
              <Link
                className={S.link}
                variant="link"
                href={needsLogin.value ? '/login' : href.value}
                openInNewTab={newTab.value}
              >
                {label.value}
              </Link>
            </li>
          )
        )}
      </ul>
    );

    desktop.push(
      <div className={S.footerBlock} key={header.label.value}>
        <div className={S.blockHeader}>{header.label.value}</div>
        {list}
      </div>
    );

    mobile.push(
      <div className={S.accordion} key={header.label.value}>
        <Accordion
          title={header.label.value}
          titleClassName={`${S.blockHeader} ${S.accordionHeader}`}
          iconDown={AccordionIconType}
          iconDownAnimation={IconAnimations.CounterClockwise}
          iconUp={AccordionIconType}
          iconUpAnimation={IconAnimations.Clockwise}
        >
          {list}
        </Accordion>
      </div>
    );
  });

  return (
    <Breakpoints>
      <Breakpoint media="desktop">{desktop}</Breakpoint>
      <Default>{mobile}</Default>
    </Breakpoints>
  );
};
