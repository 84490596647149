import { createContext } from 'react';
import { Nullable } from '@/type-utils';
import { IPage } from '@/services/models/Page';

/**
 * The Page context that includes the page model. Used in conjunction with
 * the PageService to supply page metadata to the client side based on the server side
 * props.
 */
export interface IPageContext {
  /**
   *
   */
  page: IPage;
}

export const PageContext = createContext<Nullable<IPageContext>>(null);
