import Script from 'next/script';
import { memo, useSyncExternalStore } from 'react';

/** The loading status of a shared script. */
type SharedScriptStatus = 'loading' | 'ready' | 'error';

/** A store for the status of a shared script. */
class SharedScriptStatusStore {
  private status: SharedScriptStatus = 'loading';
  private listeners: Set<VoidFunction> = new Set();

  /**
   * Subscribes a listener to changes in the script status.
   * @param listener - The listener to subscribe.
   * @returns A function to unsubscribe the listener.
   */
  public subscribe = (listener: VoidFunction): VoidFunction => {
    this.listeners.add(listener);
    return () => {
      this.listeners.delete(listener);
    };
  };

  /**
   * Returns the current status of the script.
   * @returns The current status of the script.
   */
  public getStatus = (): SharedScriptStatus => {
    return this.status;
  };

  /**
   * Sets the status of the script.
   * @param status - The new status to set.
   */
  public setStatus = (status: SharedScriptStatus): void => {
    if (this.status !== 'loading') return;

    this.status = status;
    for (const listener of this.listeners) {
      listener();
    }
  };
}

/** A global record of script sources and their shared script status stores. */
const GLOBAL_SHARED_SCRIPT_STATUS_STORE_RECORD = new Map<
  string,
  SharedScriptStatusStore
>();

/**
 * Gets the shared script store for a given script URL.
 * If one does not exist in the global record, it will be created.
 * @param src - The URL of the script to get the store for.
 * @returns The shared script store for the given script URL.
 */
function getSharedScriptStore(src: string): SharedScriptStatusStore {
  if (!GLOBAL_SHARED_SCRIPT_STATUS_STORE_RECORD.has(src)) {
    const result = new SharedScriptStatusStore();
    GLOBAL_SHARED_SCRIPT_STATUS_STORE_RECORD.set(src, result);
    return result;
  }

  return GLOBAL_SHARED_SCRIPT_STATUS_STORE_RECORD.get(src)!;
}

interface ISharedScriptProps {
  /** The URL of the script to load. */
  src: string;
  /**
   * A callback to be executed when the status of the script changes.
   * @param status - The new status of the script.
   */
  onStatusChange: (status: SharedScriptStatus) => void;
}

/** A component for loading a shared script. */
const SharedScript = memo(function SharedScript({
  src,
  onStatusChange
}: ISharedScriptProps) {
  return (
    <Script
      src={src}
      // "afterInteractive" ensures the script is deduplicated
      strategy="afterInteractive"
      onLoad={() => onStatusChange('ready')}
      onError={() => onStatusChange('error')}
    />
  );
});

/**
 * A hook for loading a script once and sharing it across components.
 * This is useful for:
 * - Keeping the script definition co-located with the components that use it.
 * - Preventing the script from loading until it's needed.
 *
 * @param src - The URL of the script to load.
 * @returns A tuple containing the JSX element for the script
 * and a boolean indicating if the script is ready.
 *
 * Note: use this hook sparingly, as it mainly exists due to a bug in Next.js.
 * Specifically, Next.js doesn't correctly execute the `onReady` callback
 * for duplicate scripts, which can cause race conditions and inconsistent behavior.
 * @see {@link https://github.com/vercel/next.js/issues/63300}
 *
 * @example
 * function ApplePayButton() {
 *  const [script, status] = useSharedScript('https://js.applepay.com');
 *  return (
 *    <>
 *      {script}
 *      {status === 'ready' && <apple-pay-button />}
 *    </>
 *  );
 * }
 */
export function useSharedScript(
  src: string
): [script: JSX.Element, status: SharedScriptStatus] {
  const { subscribe, getStatus, setStatus } = getSharedScriptStore(src);
  const status = useSyncExternalStore(subscribe, getStatus, getStatus);

  const script = <SharedScript src={src} onStatusChange={setStatus} />;

  return [script, status];
}
