import { FunctionComponent, useEffect } from 'react';

import { Slider, Slide, ISliderProps } from '../../../../Slider';
import { ContentItemModel, IContentItem } from '@/services/models/Content';
import type INestableContent from '../../../traits/INestableContent';
import { TextBanner } from '../TextBanner';

import S from './styles.module.scss';

export interface IInnerBannerProps extends INestableContent {
  /** The content item. */
  item: IContentItem;
}

/**
 * `InnerBanner` is made up of one or more `TextBanner`.
 * If a teaser is passed to it, we will have only one `TextBanner`
 * in the UI, if a collection with more than one item is passed,
 * a sliding `TextBanner` will be displayed in the UI.
 */
export const InnerBanner: FunctionComponent<IInnerBannerProps> = ({
  item,
  contentResolver
}) => {
  const itemModel = ContentItemModel.from(item);

  if (!itemModel.items || itemModel.items.length === 0) {
    return <TextBanner contentResolver={contentResolver} item={itemModel} />;
  }

  if (itemModel.items.length === 1 && itemModel.item) {
    return (
      <TextBanner contentResolver={contentResolver} item={itemModel.item} />
    );
  }

  const sliderProps: ISliderProps = {
    direction: 'horizontal',
    loop: true,
    navigation: false,
    pagination: false,
    height: 'auto',
    autoplay: {
      delay: 4000
    }
  };

  return (
    <div className={S.container}>
      <Slider {...sliderProps}>
        {itemModel.items.map((item) => {
          return (
            <Slide key={item.id}>
              <TextBanner contentResolver={contentResolver} item={item} />
            </Slide>
          );
        })}
      </Slider>
    </div>
  );
};
