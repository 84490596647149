'use client';

import { FunctionComponent } from 'react';
import ConfigurationService from '@/services/isomorphic/ConfigurationService';
import { Button } from '@/react/components/core-ui/Button';
import I18NService, { msg } from '@/services/isomorphic/I18NService';
import { Icon, IconSizeProp, IconTypes } from '@/react/components/core-ui/Icon';
import { classes } from '@/next-utils/css-utils/scss-utils';
import { Link } from '../../../core-ui/Link';

import S from './styles.base.module.scss';
import { footer_cookiePreferences } from "@/lang/__generated__/ahnu/footer_cookiePreferences";

/**
 * Links to legal documentation pages and accessibility.
 */
export const FooterBottom: FunctionComponent = () => {
  const config = ConfigurationService.getConfig('footer');
  const osanoConfig = ConfigurationService.getConfig('osano');
  const osanoEnabled = osanoConfig.getSetting('enabled').value;
  const footerSubMenu = config.getSetting('footerSubMenu').value;
  const accessibility = footerSubMenu.find((item) => {
    return item.id.value === 'accessibility';
  });
  const locale = I18NService.currentLocale.locale || 'US';
  const flagIconType = `${locale}Flag` as keyof typeof IconTypes;

  return (
    <div className={S.content}>
      <ul className={S.list}>
        {footerSubMenu?.length &&
          footerSubMenu.map(({ id, label, href, newTab }) => (
            <>
              {id.value === 'cookies-preference' && osanoEnabled && (
                <li className={S.item}>
                  <Button
                    variant="text"
                    onClick={() =>
                      window.Osano.cm.showDrawer(
                        'osano-cm-dom-info-dialog-open'
                      )
                    }
                    className={S.link}
                  >
                    {msg(footer_cookiePreferences)}
                  </Button>
                </li>
              )}
              {id.value !== 'cookies-preference' && (
                <li className={S.item} key={id.value}>
                  <Link
                    variant="text"
                    href={href.value}
                    className={S.link}
                    openInNewTab={newTab.value}
                  >
                    {id.value === 'international' && (
                      <Icon
                        className={classes(S.icon, S.flag)}
                        icon={IconTypes[flagIconType]}
                        size={IconSizeProp.SizeSM}
                      />
                    )}
                    {label.value}
                  </Link>
                </li>
              )}
            </>
          ))}
      </ul>
      <div className={S.accessibilityLink}>
        <Link
          href={
            config.getSetting('accessibilityIcon.href').value ??
            accessibility?.href?.value
          }
          openInNewTab={config.getSetting('accessibilityIcon.newTab').value}
        >
          <img
            className={S.accessibilityImage}
            src="/images/Deckers_eA_Icon_transparent.png"
            alt="essential accessibility"
          />
        </Link>
      </div>
    </div>
  );
};
