import {
  CSSProperties,
  SyntheticEvent,
  EventHandler,
  PropsWithChildren
} from 'react';
import IStylable from '../../traits/IStylable';

/** Type that reprensents all CSS properties. */
export type StyleProps = {
  [Key in keyof CSSProperties]: CSSProperties[Key];
};

export const allowedElements = [
  'a',
  'button',
  'div',
  'h1',
  'h2',
  'h3',
  'h4',
  'h5',
  'h6',
  'li',
  'p',
  'span',
  'ul'
] as const;

/** Allowed Tags for dynamic tag. */
export type AllowedElements = typeof allowedElements[number];

export interface IBoxProps extends PropsWithChildren, StyleProps, IStylable {
  /** Wrapping tag for the text. */
  as?: AllowedElements;

  /** HTML Title attribute. */
  title?: string;

  /** Accessible label for the interactive element. */
  ariaLabel?: string;

  /** HTML button type attribute. */
  type?: 'submit' | 'button';

  /** Item key. */
  key?: string;

  /** HTML disabled attribute.  */
  disabled?: boolean;

  /**
   * Click event.
   */
  onClick?: EventHandler<SyntheticEvent>;

  /** Key press event. */
  onKeyPress?: EventHandler<SyntheticEvent>;
}
