/**
 * A list of payment methods.
 */
export enum PaymentMethodName {
  CREDIT_DEBIT_CARD = 'creditCard',
  PAYPAL = 'payPal',
  APPLE_PAY = 'applePay',
  ENDLESS_AISLE = 'endlessAisle',
  /**
   * The NONE state indicates that the order does not require a primary
   * payment method due to applied gift cards. This is dependent on being
   * set from the outside based on the cart state.
   */
  NONE = 'none'
}

export default PaymentMethodName;
