'use client';

import { FunctionComponent, useRef, useContext, MutableRefObject } from 'react';

import { classes } from '@/next-utils/css-utils/scss-utils';
import { Nullable } from '@/type-utils';
import { InvalidArgumentError } from '@/utils/errors/InvalidArgumentError';
import { useOnClickedOutside } from '@/react/hooks/useOnClickOutside';

import { msg } from '@/services/isomorphic/I18NService';
import {
  IBasicNotification,
  ICustomNotification,
  IQueuedNotification
} from '../../types';

import NotificationType from '../../NotificationType';
import BasicNotificationBody from '../BasicNotificationBody';
import { NotificationContext } from '../../controller';

import { Button } from '../../../../core-ui/Button';
import { Icon, IconTypes } from '../../../../core-ui/Icon';
import S from './styles.module.scss';
import { general_closeNotification } from "@/lang/__generated__/ahnu/general_closeNotification";

/** Describes the props for the {@link Notification} component. */
export interface INotificationProps {
  /** Notification to render. */
  notification: Nullable<IQueuedNotification>;
}

/**
 * Renders a queued notification.
 * @throws An {@link InvalidArgumentError} when receiving a notification of an invalid type.
 */
const Notification: FunctionComponent<INotificationProps> = ({
  notification
}) => {
  let content: Nullable<JSX.Element> = null;
  const wrapperRef = useRef<HTMLDivElement>(null);

  const { deleteNotification } = useContext(NotificationContext);

  if (notification)
    switch (notification.type) {
      case NotificationType.Basic: {
        content = (
          <BasicNotificationBody
            notification={notification as IBasicNotification}
          />
        );
        break;
      }

      case NotificationType.Custom: {
        content = (notification as ICustomNotification).content;
        break;
      }

      default: {
        throw new InvalidArgumentError(
          `Received notification with invalid type: ${notification.type}`
        );
      }
    }

  useOnClickedOutside(wrapperRef as MutableRefObject<HTMLDivElement>, () => {
    if (notification) {
      deleteNotification(notification.uuid);
    }
  });

  return (
    <div className={classes(S.headsUpNotification)} ref={wrapperRef}>
      <div
        className={classes(
          S.content,
          notification?.options?.isError ? S.error : '',
          {
            [S.visible]: !!notification && !!content
          }
        )}
      >
        <Button
          className={S.close}
          onClick={() => {
            if (notification) {
              deleteNotification(notification.uuid);
            }
          }}
          variant="text"
          ariaLabel={msg(general_closeNotification)}
        >
          <Icon className={S.closeIcon} icon={IconTypes.Close} />
        </Button>
        {content}
      </div>
    </div>
  );
};

export default Notification;
