'use client';

import ConfigurationService from '@/services/isomorphic/ConfigurationService';
import { EnvironmentService } from '@/services/isomorphic/EnvironmentService';
import PaymentMethodsService from '@/services/isomorphic/PaymentMethodsService';
import type { ReactNode } from 'react';
import { useSharedScript } from './useSharedScript';

/**
 * A hook for enabling the use of Apple Pay.
 * @returns A tuple containing a ReactNode script to load the Apple Pay SDK,
 * and a boolean indicating whether Apple Pay features can be used. The script
 * must be rendered in the component tree to toggle whether Apple Pay is enabled.
 */
export function useApplePay(): [script: ReactNode, isEnabled: boolean] {
  const sdkURL =
    ConfigurationService.getConfig('applePay').getSetting('sdkURL').value;
  const [script, status] = useSharedScript(sdkURL);

  /**
   * We must check that the status of the script is 'ready' before checking
   * whether Apple Pay is enabled because the SDK may load APIs that are
   * required to determine if we should show the button.
   */
  const isEnabled =
    status === 'ready' &&
    // always show in Storybook
    ((typeof __isolatedContext__ !== "undefined") ||
      PaymentMethodsService.isApplePayEnabled());

  return [script, isEnabled];
}
