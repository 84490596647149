import Service from '@/services/Service';
import { PaymentMethodName } from '@/services/models/Order';
import ConfigurationService from '../ConfigurationService';
import { EnvironmentService } from '../EnvironmentService';

/**
 * The `PaymentMethodsService` is used to handle operations related to payment methods.
 *
 * Specifically, it provides a method called `getAvailableExpressPaymentMethods()`
 * that retrieves the available express payment methods.
 *
 * Express payment methods are typically integrations with third-party payment
 * service providers or payment gateways that offer fast and secure payment processing.
 * Examples of express payment methods include Apple Pay, Google Pay, PayPal, and others.
 *
 * This service is important because it encapsulates the logic for retrieving and handling payment methods.
 */
export class PaymentMethodsService extends Service {
  /**
   * Get the available express payment methods.
   *
   * Express payment methods typically involve integrating with third-party
   * payment service providers or payment gateways that offer fast and secure payment
   * processing.
   *
   * Some examples of express payment methods include: Apple Pay, Google Pay, PayPal, etc..
   * @returns The available express payment methods.
   */
  public getAvailableExpressPaymentMethods(): Array<string> {
    const availableExpressPaymentMethods = [];

    if (this.isApplePayEnabled()) {
      availableExpressPaymentMethods.push(PaymentMethodName.APPLE_PAY);
    }

    return availableExpressPaymentMethods;
  }

  /**
   * Check if Apple Pay is enabled.
   *
   * When called in the `browser`, this method checks
   * if Apple Pay is enabled by checking the `enabled` config and the presence of the
   * `ApplePaySession` object in the window object, but when called in the `server`, it
   * only checks the `enabled` config, because the `ApplePaySession` object is not available
   * in the server.
   * @returns True if Apple Pay is enabled, false otherwise.
   */
  public isApplePayEnabled(): boolean {
    const applePayConfig = ConfigurationService.getConfig('applePay');
    const enabledApplePay = applePayConfig.getSetting('enabled').value;

    if ((typeof window !== "undefined")) {
      const applePayVersion = applePayConfig.getSetting('version').value;

      // We are checking for ApplePaySession in the window object to determine if
      // Apple Pay is supported in the browser, instead of directly checking ApplePaySession as we do in.
      // @see {@link ApplePayService}, because accessing ApplePaySession directly in a browser other than Safari
      // will cause the app to crash. In ApplePayService, we don't need to worry about this as it always runs
      // when interacting with the Apple Pay button, which is only rendered in browsers that support Apple Pay, such as Safari.
      return Boolean(
        enabledApplePay &&
          // Apple Pay is only available over HTTPS
          window.location.protocol === 'https:' &&
          // ensure the version used by the site is supported by the browser
          window.ApplePaySession?.supportsVersion(applePayVersion) &&
          window.ApplePaySession.canMakePayments()
      );
    }

    return enabledApplePay;
  }
}

export default new PaymentMethodsService();
